import { TableCell, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { RootState } from "app/rootReducer";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { ListState, Normalized } from "components/Middlewares/listSliceCreator";
import React from "react";
import { useSelector } from "react-redux";
import { CrudEntity } from "utils/types";
import { formatScores } from "../../CreditScore/CheckCreditScore";
import { formatCreditBureausScores } from "../../CreditScore/CreditBureauView";
import Table, { CellValue, Column, Entry } from "../../Table";
import AddManager from "../AddManager";
import { getStyleByStatus, projections, statusToChipTooltip, statusToLabel } from "../Deals";
import { deleteDeal } from "../deleteDealSlice";
import { getDealList } from "../listDealSlice";
import { recoverDeal } from "../recoverDealSlice";
import { Deal, DealData, DealStatus } from "../types";
import { dealsOrder } from "./dealOrderSlice";
const filterLeadsAndCreditCheckFromSlice = (
  dealSlice: ListState<
    CrudEntity & {
      data: DealData;
    } & Normalized
  >
) => {
  const entities = dealSlice?.entities?.filter((deal: Deal) => {
    if (
      [DealStatus.Lead, DealStatus.CreditCheck, DealStatus.DidNotProcess].includes(
        deal.data.info.status
      )
    ) {
      return false;
    }
    // if (deal.data.info.status === DealStatus.DidNotProcess) {
    //   return (
    //     typeof deal?.data?.info?.collateralReceivedDate === "string" ||
    //     typeof deal?.data?.info?.creditCheckDate === "string"
    //   );
    // }
    return true;
  });

  return {
    ...dealSlice,
    entities,
    total: entities?.length ?? 0
  };
};
export type DateRangeQuery = { $gte: string; $lte: string };

export const dateRangeQuery = (x: Date): DateRangeQuery => {
  const beginningOfTheDay = new Date(x);
  beginningOfTheDay.setHours(0, 0, 0, 0);
  const endOfTheDay = new Date(x);
  endOfTheDay.setHours(23, 59, 59, 999);
  return {
    $gte: beginningOfTheDay.toISOString(),
    $lte: endOfTheDay.toISOString()
  };
};
const toolbarStyle = {
  textAlign: "center",
  background: "#254e70",
  color: "#fff",
  minHeight: "0px"
};
const columns: (date: Date) => Column<DealData>[] = (date) => [
  {
    getData: (entry): CellValue => entry.data?.info?.refNumber,
    label: "Ref #",
    options: {
      sort: false,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter,
        name: "refNumber",
        label: "Ref Number"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      [
        entry.data?.applicant
          ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,
        entry.data?.coApplicant
          ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
              entry.data?.coApplicant?.data?.info?.lastName ?? ""
            }`
          : undefined
      ]
        .filter((x) => x)
        .join(" / "),
    label: "Customer",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "firstName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter,
        caseInsensitive: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
    label: "Dealership",
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter,
        name: "dealership",
        label: "Dealership"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter,
        name: "make",
        label: "Vehicle make"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter,
        name: "model",
        label: "Vehicle Model"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.info?.extractedCreditScores?.applicant?.score || "N/A"} ${
        entry.data?.info?.extractedCreditScores?.coApplicant?.score
          ? "/ " + entry.data?.info?.extractedCreditScores?.coApplicant?.score || "N/A"
          : ""
      }`,
    label: "Credit Score",
    name: "creditScore",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.creditScore?.show;
    },
    filters: [
      {
        path: ["data", "info", "extractedCreditScores", "applicant", "score"],
        preview: PriceFilter,
        name: "applicantScore",
        valuesForSelect: [0, 1000],
        label: "Applicant Score"
      },
      {
        path: ["data", "info", "extractedCreditScores", "coApplicant", "score"],
        preview: PriceFilter,
        valuesForSelect: [0, 1000],
        name: "coApplicantScore",
        label: "Co-Applicant Score"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter,
        name: "lender",
        label: "Lender"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    options: {
      customBodyRender: (user: CellValue, deal): JSX.Element | string => {
        return (
          <div style={{ minWidth: "150px" }}>
            <AddManager deal={deal} />
          </div>
        );
      }
    },
    name: "f&iManager",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        name: "f&iManager",
        label: "F&I Manager",
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
        entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
      }`,
    label: "Rep",
    name: "rep",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.rep?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "representativeId"],
        preview: UserFilter,
        type: "representative"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.status,
    label: "Status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
      },
      sort: false,
      path: ["data", "info", "status"]
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
    label: "Funded",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter,
        name: "fundedAmount"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry._id,
    label: "Actions",
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
];

const localSort = (
  deals: (CrudEntity & {
    data: DealData;
  } & Normalized)[]
) => {
  return deals?.sort((a, b) => {
    if (a?.data?.order && b?.data?.order) {
      if (a.data.order > b.data.order) return 1;
      if (a.data.order === b.data.order) return 0;
      if (a.data.order < b.data.order) return -1;
    }
    return 0;
  });
};
const formatDays = (n: number) => {
  return `(${n})`;
};
export default ({ sliceName, index, date }: { sliceName: string; index: number; date: Date }) => {
  const listDealSlice = useSelector(
    (state: RootState) => state.listDealSlice[sliceName] ?? { entities: undefined, total: 0 }
  );
  const dealData = filterLeadsAndCreditCheckFromSlice(listDealSlice);
  const autoPSDeals = (dealData.entities ?? []).filter(
    (deal) => deal.data.info.type === "Automotive" || deal.data.info.type === "Power Sport"
  );
  const MarineRV = (dealData.entities ?? []).filter(
    (deal) => deal.data.info.type === "Marine" || deal.data.info.type === "Recreational Vehicles"
  );
  return (
    <Paper
      elevation={3}
      style={{ margin: "10px 0px", padding: "0px" }}
      key={index + date.toISOString()}
    >
      <Table
        tableName="deals_log"
        parentId={date.toISOString().slice(0, 10)}
        toolbarStyle={toolbarStyle}
        rowsPerPage={1000}
        customCellFontSize={"12px"}
        hideSearch={true}
        hideCalendar={true}
        hideRecoverButton={true}
        hideExcelExportButton={true}
        hideAddButton={true}
        hidePrintButton={true}
        withOrder={true}
        isDraggable={true}
        orderSlice={dealsOrder}
        key={index}
        elevation={0}
        entityName={"deal"}
        sort={{ "data.order": "asc" }}
        slice={sliceName}
        listFunction={getDealList}
        listEntity={{
          ...dealData,
          entities: dealData.entities
            ? [
                {
                  customRow: (attrs) => (
                    <TableRow {...attrs}>
                      <TableCell
                        colSpan={13}
                        style={{
                          textAlign: "center",
                          background: "#e0e0e0",
                          fontWeight: "bold"
                        }}
                      >
                        Automotive/Power Sport ({autoPSDeals.length})
                      </TableCell>
                    </TableRow>
                  )
                },
                ...localSort(autoPSDeals),
                {
                  customRow: () => (
                    <TableRow>
                      <TableCell
                        colSpan={13}
                        style={{
                          textAlign: "center",
                          background: "#e0e0e0",
                          fontWeight: "bold"
                        }}
                      >
                        RV/Marine ({MarineRV.length})
                      </TableCell>
                    </TableRow>
                  )
                },
                ...localSort(MarineRV)
              ]
            : undefined
        }}
        query={{
          $or: [
            {
              "data.info.collateralReceivedDate": dateRangeQuery(date)
            },
            {
              "data.info.creditCheckDate": dateRangeQuery(date)
            },
            { createdAt: dateRangeQuery(date) }
            // { "data.info.pendingDate": dateRangeQuery(date) }
            //Ariel requested removing pendingDate from the query - older deals received pending date were popping up in deals log
            //10.10.2022
          ]
        }}
        deleteEntityFunction={deleteDeal}
        recoverEntityFunction={recoverDeal}
        title={`${new Intl.DateTimeFormat("en-Us", {
          year: "numeric",
          month: "long",
          day: "numeric"
        }).format(date)} ${
          typeof dealData.entities?.length === "number" ? formatDays(dealData.entities.length) : ""
        }`}
        columns={columns(date)}
        projection={{
          ...projections,
          "data.info.collateralReceivedDate": 1,
          "data.info.creditCheckDate": 1
        }}
        dealLogTable={true}
        hidePagination={true}
      />
    </Paper>
  );
};
