import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import axios from "axios";
import { DealerNote } from "components/DealerNotes/types";
import { Deal } from "components/Deals/types";
import { sendNewVersionNotification } from "components/Layout/VersionNotification";
import { setConnected, setDisconnected } from "components/Loader/SocketConnectionStatus";
import { PrintedDocument } from "components/PrintedDocuments/types";
import objectHash from "object-hash";
import io from "socket.io-client";
import { getApiStatusAction } from "utils/Monitoring/listApiStatusSlice";
import { assertNever } from "utils/functions";
import { devlog, notifyWebhooks } from "utils/logging";
import { AppDispatch, AppThunk } from "../../app/store";
import { auth } from "../../firebase/firebase";
import {
  EntityData,
  EntityType,
  convertToInternalFormat,
  entityConfigs,
  entitySlice
} from "../../utils/entitySlice";
import { receiveSignIn, setPermissions } from "../SignIn/authSlice";
import { HttpRequestPaths, RequestsTopics, httpRequestPaths } from "./socketMessageSliceCreator";
import { CustomerCareNote } from "components/CustomerCareNotes/types";

//import sizeof from "object-sizeof";
export interface Version {
  message: {
    _id: string;
    version: string;
    deleted: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
}

// TODO: this should be made more concrete
interface ResponsePayload {
  message: any;
  status: "success" | "error";
}

interface RestResponse {
  data: {
    result: any;
    status: "success" | "error";
  };
}

const capitalizeAndRemoveDashes = (s: string) => {
  return s
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
const getListIdByEntity = (entity: EntityType, payload: any): string[] => {
  switch (entity) {
    case "checklist":
      if (payload?.data?.dealId) {
        return [payload?.data?.dealId];
      }
      return [];
    case "customer_care_note": {
      if (payload?.data?.dealershipId) {
        return [payload?.data?.dealershipId];
      }
      return [];
    }
    case "note":
    case "dealer_note":
    case "stipulation":
      if (payload?.data?.dealId) {
        return [payload?.data?.dealId];
      }
      if (payload?.data?.lenderTicketId) {
        return [payload?.data?.lenderTicketId];
      }
      if (payload?.data?.titleIssueId) {
        return [payload?.data?.titleIssueId];
      }

      if (payload?.data?.dealershipTicketId) {
        return [payload?.data?.dealershipTicketId];
      }
      return [];
    case "title_issue_note":
      if (payload?.data?.titleIssueId) {
        return [payload?.data?.titleIssueId];
      }
      return [];
    case "dealership_ticket_note":
      if (payload?.data?.dealershipTicketId) {
        return [payload?.data?.dealershipTicketId];
      }
      return [];
    case "lender_note":
      if (payload?.data?.ticketId) {
        return [payload.data.ticketId];
      }
      return [];
    case "file":
      if (payload?.data?.dealId) {
        return [payload?.data?.dealId];
      }
      if (payload?.data?.contractId) {
        if (payload?.data?.isAdditionalContractDocument)
          return [payload?.data?.contractId + "_additionalContractDocumentsSlice"];
        else return [payload?.data?.contractId];
      }
      if (payload?.data?.documentTemplateId) {
        return [payload?.data?.documentTemplateId];
      }
      if (payload?.data?.chargebackId) {
        return [payload?.data?.chargebackId];
      }
      if (payload?.data?.lenderTicketId) {
        return [payload?.data?.lenderTicketId];
      }
      return [];
    case "title_issue":
      if (payload?.data?.dealId) {
        return [payload?.data?.dealId];
      }
      return [];
    case "funding_document":
      if (payload?.data?.dealId) {
        return [payload?.data?.dealId];
      }
      return [];
    case "contract":
      return [
        ...(payload?.data?.dealershipId ? [payload?.data?.dealershipId] : []),
        ...(payload?.data?.lenderId ? [payload?.data?.lenderId] : [])
      ];
    default:
      return [];
  }
};

export const initializeSocket = async (token: string, url: string, storeAPI: MiddlewareAPI) => {
  const socket = io(url + "/admin", {
    auth: { token },
    query: { connectedAt: new Date().getTime() }
  });
  socket.on("connect", () => {
    storeAPI.dispatch({ type: setConnected.type });
    devlog(`Connected to ${url}!`);
  });
  socket.on("connect_error", async (...message) => {
    socket.disconnect();

    setTimeout(async () => {
      initializeSocket((await auth.currentUser?.getIdToken()) as string, url, storeAPI);
    }, 1000);

    console.error("CONNECTION ERROR", message);
  });
  socket.on("error", (message: string) => {
    storeAPI.dispatch({ type: setDisconnected.type });

    devlog("error: ", JSON.parse(message));
    notifyWebhooks(message);

    if (JSON.parse(message).message === "User not approved.") {
      setTimeout(function () {
        window.location.href = "";
      }, 5000);
      auth.signOut().then(() => {
        alert(
          "User not approved.Once the user is approved, you will receive an email with confrimation.\nYou will be redirected to homepage after 5 seconds!"
        );
      });
    } else if (JSON.parse(message).message.includes("Firebase ID token has expired.")) {
      window.location.href = "";
    } else {
      setTimeout(function () {
        window.location.href = "";
      }, 5000);
      alert("Server error. You will be redirected to homepage after 5 seconds!");
    }
  });

  socket.on("disconnect", (message: string) => {
    storeAPI.dispatch({
      type: setDisconnected.type
    });

    devlog("Disconnected!");
  });
  const currentUser = auth.currentUser?.email ?? "";
  socket.on(`notification_${currentUser}`, (payload: any) => {
    storeAPI.dispatch({
      type: entityConfigs["notification" as EntityType].list.sliceActions.addToList.type,
      payload: { payload: payload.data, listId: "notifications" }
    });
  });
  socket.on(`${currentUser}_deal_status_changed`, (payload: any) => {
    window.dispatchEvent(
      new CustomEvent(`display_notification`, {
        detail: { message: `Deal status changed to ${payload.status}!` }
      })
    );
  });

  socket.on(`notification_updated_${currentUser}`, (payload: any) => {
    const currentListState =
      storeAPI.getState()["listNotificationSlice"]["notifications"]?.entities ?? [];
    const index = currentListState?.findIndex((el: any) => el._id === payload.data._id);
    storeAPI.dispatch({
      type: entityConfigs["notification" as EntityType].list.sliceActions.editList.type,
      payload: { payload: { ...payload, data: payload.data.data }, index, listId: "notifications" }
    });
  });

  socket.on("api_status_updated", (payload: any) => {
    storeAPI.dispatch({
      type: entityConfigs["api_status" as EntityType].list.sliceActions.replaceOrCreateList.type,
      payload: { payload: payload.components, listId: "table" }
    });
  });

  socket.on("new_version", (payload: Version) => {
    storeAPI.dispatch({
      type: sendNewVersionNotification.type,
      payload: payload.message.version
    });
  });
  socket.on(
    `${currentUser}_live_updates`,
    async (payload: { action: "create" | "update"; entity: EntityType; entry: any }) => {
      const entity = payload.entity;
      const entry = payload.entry;
      if (payload.action === "create") {
        devlog(`Websocket create: ${entity}`, payload);
        switch (entity) {
          case "deal":
            if ((entry as EntityData<typeof entity>).data.info.status === "lead")
              storeAPI.dispatch({
                type: entityConfigs[entity].list.sliceActions.addToList.type,
                payload: { payload: entry, listId: "leads" }
              });
            storeAPI.dispatch({
              type: entityConfigs[entity].list.sliceActions.addToList.type,
              payload: { payload: entry, listId: "table" }
            });
            storeAPI.dispatch({
              type: entityConfigs[entity].list.sliceActions.addToList.type,
              payload: {
                payload: entry,
                addToEnd: true,
                listId: "table_" + new Date(entry.createdAt).toISOString().slice(0, 10)
              }
            });
            break;
          case "printed_document":
            storeAPI.dispatch({
              type: entityConfigs[entity].list.sliceActions.addToList.type,
              payload: {
                payload: entry,
                listId: `printed_documents_table-${entry.data.dealId}`
              }
            });
            Object.values<Deal>(storeAPI.getState()?.[`entitySlice`]?.["deal"] ?? {}).forEach(
              (deal) => {
                if (entry.data.dealId === deal._id) {
                  const oldPrintedDocuments =
                    deal.data?.printedDocuments?.filter(
                      (printedDocument: PrintedDocument) => printedDocument._id !== entry._id
                    ) || [];
                  storeAPI.dispatch({
                    type: entitySlice.actions.update.type,
                    payload: {
                      payload: {
                        ...deal,
                        data: {
                          ...deal.data,
                          printedDocuments: [...oldPrintedDocuments, entry]
                        }
                      },
                      entity: "deal",
                      _id: deal._id
                    }
                  });
                }
              }
            );
            break;
          case "lender_decision":
            //@ts-ignore
            if (Array.isArray(entry.entities) && entry.entities.length > 0) {
              storeAPI.dispatch({
                type: entityConfigs[entity].list.sliceActions.addToList.type,
                //@ts-ignore
                payload: { payload: entry.entities, listId: "table" }
              });
            } else if (entry?._id) {
              storeAPI.dispatch({
                type: entityConfigs[entity].list.sliceActions.addToList.type,
                payload: { payload: entry, listId: "table" }
              });
            }
            break;
          case "document_template":
            if (entry?.data?.info?.type === "representativeDocument")
              storeAPI.dispatch({
                type: entityConfigs[entity].list.sliceActions.addToList.type,
                payload: { payload: entry, listId: "representativeDocument" }
              });
            storeAPI.dispatch({
              type: entityConfigs[entity].list.sliceActions.addToList.type,
              payload: { payload: entry, listId: "table" }
            });
            break;
          default:
            if (entityConfigs?.[entity]?.list?.sliceActions) {
              storeAPI.dispatch({
                type: entityConfigs[entity].list.sliceActions.addToList.type,
                payload: { payload: entry, listId: "table" }
              });

              const listIds = getListIdByEntity(entity, entry);
              listIds.forEach((listId: string) => {
                storeAPI.dispatch({
                  type: entityConfigs[entity].list.sliceActions.addToList.type,
                  payload: { payload: entry, listId: listId }
                });
              });
            }
        }
      }

      if (payload.action === "update") {
        //@ts-ignore
        if (entity === "permission_group") {
          getPermission(
            {
              payload: {
                token: (await auth.currentUser?.getIdToken()) as string,
                email: auth.currentUser?.email
              }
            },
            storeAPI
          );
        }

        if (entity === "note") return;
        // if (entity === "dealer_note") return;
        devlog(`Websocket updated ${entity}: `, entry);

        const currentListState = storeAPI.getState()[
          `list${capitalizeAndRemoveDashes(entity)}Slice`
        ];
        if (entity === "stipulation") {
          if (entry.deleted) {
            const foundIndex = currentListState[entry.data.dealId]?.entities?.findIndex(
              (el: any) => el._id === entry._id
            );
            const index =
              foundIndex >= 0
                ? foundIndex
                : currentListState[entry.data.dealId]?.entities?.length || 0;
            storeAPI.dispatch({
              type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
              payload: { payload: entry, index, listId: entry.data.dealId }
            });
          }
          return;
        }
        if (entity === "dealer_note") {
          const note: DealerNote = entry;
          if (note.data.info.sentToDealerEmailDate) {
            const foundIndex = currentListState[entry.data.dealId]?.entities?.findIndex(
              (el: any) => el._id === entry._id
            );
            const index =
              foundIndex >= 0
                ? foundIndex
                : currentListState[entry.data.dealId]?.entities?.length || 0;
            storeAPI.dispatch({
              type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
              payload: { payload: entry, index, listId: entry.data.dealId }
            });
          }
          return;
        }
        if (entity === "customer_care_note") {
          const note: CustomerCareNote = entry;
          if (note.data.info.sentToRepEmailDate) {
            const foundIndex = currentListState[entry.data.dealershipId]?.entities?.findIndex(
              (el: any) => el._id === entry._id
            );
            const index =
              foundIndex >= 0
                ? foundIndex
                : currentListState[entry.data.dealershipId]?.entities?.length || 0;
            storeAPI.dispatch({
              type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
              payload: { payload: entry, index, listId: entry.data.dealershipId }
            });
          }
          return;
        }
        //console.log("currentListState", currentListState);
        if (currentListState) {
          if (entity === "lock") {
            const foundIndex = currentListState["all"]?.entities?.findIndex(
              (el: any) => el._id === entry._id
            );
            const index =
              foundIndex >= 0 ? foundIndex : currentListState["all"]?.entities?.length || 0;
            storeAPI.dispatch({
              type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
              payload: { payload: entry, index, listId: "all" }
            });
          } else {
            Object.keys(currentListState).forEach((listId) => {
              const index = currentListState[listId].entities?.findIndex(
                (el: any) => el._id === entry._id
              );

              const foundEntry = currentListState[listId].entities?.[index];

              if (
                foundEntry &&
                ((entity === "deal" && entry.data.order !== foundEntry.data.order) ||
                  new Date(entry.updatedAt).getTime() > new Date(foundEntry.updatedAt).getTime())
              ) {
                const potentiallyChangedData =
                  entry.data !== undefined
                    ? convertToInternalFormat(entity, entry.data)
                    : entry.data;
                storeAPI.dispatch({
                  type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
                  payload: {
                    payload: { ...entry, data: potentiallyChangedData },
                    index,
                    listId
                  }
                });
              }
            });
          }
        }

        // if (entity === "one_span_signing" || entity === "printed_document") {
        //   const resolvedEntityPath =
        //     entity === "one_span_signing" ? "oneSpanSignings" : "printedDocuments";
        //   Object.values<Deal>(storeAPI.getState()?.[`entitySlice`]?.["deal"] ?? {}).forEach(
        //     (deal) => {
        //       if (entry.data.dealId === deal._id) {
        //         const entries =
        //           (deal.data?.[resolvedEntityPath] as (
        //             | OneSpanSigning
        //             | PrintedDocument
        //           )[])?.map((x: OneSpanSigning | PrintedDocument) =>
        //             x._id === entry._id ? entry : x
        //           ) || [];
        //         storeAPI.dispatch({
        //           type: entitySlice.actions.update.type,
        //           payload: {
        //             payload: {
        //               ...deal,
        //               data: {
        //                 ...deal.data,
        //                 [resolvedEntityPath]: entries
        //               }
        //             },
        //             entity: "deal",
        //             _id: deal._id
        //           }
        //         });
        //       }
        //     }
        //   );
        // }
        const entityEntry = storeAPI.getState()?.[`entitySlice`]?.[entity]?.[entry._id];

        if (
          entityEntry &&
          (new Date(entry.updatedAt).getTime() > new Date(entityEntry.updatedAt).getTime() ||
            entity === "document_template")
        ) {
          if (entry.data !== undefined) {
            entry.data = convertToInternalFormat(entity as any, entry.data);
          }

          storeAPI.dispatch({
            type: entitySlice.actions.update.type,
            payload: { payload: entry, entity, _id: entry._id }
          });
          if (entity === "lender") {
            Object.values<Deal>(storeAPI.getState()?.[`entitySlice`]?.["deal"] ?? {}).forEach(
              (deal) => {
                if (entry._id === deal.data.lenderId) {
                  storeAPI.dispatch({
                    type: entitySlice.actions.update.type,
                    payload: {
                      payload: {
                        ...deal,
                        data: {
                          ...deal.data,
                          lender: entry,
                          lenderId: entry?._id
                        }
                      },
                      entity: "deal",
                      _id: deal._id
                    }
                  });
                }
              }
            );
          }
        }
      }
    }
  );
  return socket;
};

const alreadyRequested: any = {};
const getPermission = (action: any, storeAPI: any) => {
  makeHttpRequestWithResponse({
    method: "get",
    entity: "current_user_permissions",
    path: "current_user_permissions",
    body: action.payload
  })
    .then((payload: RestResponse) => {
      if (payload.data.status === "success") {
        storeAPI.dispatch({
          type: setPermissions.type,
          payload: payload.data.result
        });
      }
      // @ts-ignore
      if (payload?.data?.result?.api_status?.read?.hasPermission)
        storeAPI.dispatch(getApiStatusAction("table"));
    })
    .catch((error) => {
      devlog("Failed to get permissions: ", error);
      setTimeout(() => {
        getPermission(action, storeAPI);
      }, 3000);
    });
};
export const createMySocketMiddleware = (url: string): Middleware => {
  return (storeAPI: MiddlewareAPI) => {
    return (next: AppDispatch) => (action: AppThunk & AnyAction) => {
      if (action.type === receiveSignIn.type) {
        initializeSocket(action.payload.token, url, storeAPI);

        getPermission(action, storeAPI);
      }
      if (typeof action.http !== "undefined") {
        const hash = objectHash(action);
        if (alreadyRequested[hash]) return;
        alreadyRequested[hash] = true;
        setTimeout(() => {
          delete alreadyRequested[hash];
        }, 1000);

        const request = transformOldTopicToAxiosRequest(action.http.path, action.payload);

        if (action.name && action.id) {
          storeAPI.dispatch({
            type: `${action.name}/setWaiting`,
            hash,
            payload: action.id
          });
        }
        //@ts-ignore
        makeHttpRequestWithResponse(request, action.http.path)
          .then((payload: unknown) => {
            // console.log("RECEIVED ON ", (payload as any).config.url, " ", payload);
            if (typeof payload === "object") {
              if (request?.method === "post") {
                resolveCreateResponse(
                  storeAPI,
                  (request.entity as unknown) as EntityType,
                  (payload as any).data.result
                );
              }
              if (request?.method === "put") {
                resolveUpdateResponse(
                  storeAPI,
                  (request.entity as unknown) as EntityType,
                  (payload as any).data.result
                );
              }
              if (request?.entity === "notification" && request.method === "get") {
                const notificationListState =
                  storeAPI.getState()[`listNotificationSlice`]["table"]?.entities ?? [];
                const message = {
                  ...(payload as any).data.result,
                  docs: [...notificationListState, ...(payload as any).data.result.docs]
                };
                storeAPI.dispatch({
                  type: action.http.success.type,
                  payload: {
                    message,
                    status: (payload as any).data.status,
                    ...(action.http.success.additionalOptions ?? {})
                  }
                });
              } else {
                storeAPI.dispatch({
                  type: action.http.success.type,
                  hash,
                  payload: {
                    message: (payload as any).data.result,
                    status: (payload as any).data.status,
                    ...(action.http.success.additionalOptions ?? {})
                  }
                });
              }
            }
          })
          .catch((payload) => {
            console.log(payload);
            storeAPI.dispatch({
              type: action.http.error.type,
              payload: {
                message: payload,
                action: action,
                ...(action.http.success.additionalOptions ?? {})
              }
            });
          });

        return next(action);
      }

      return next(action);
    };
  };
};

const resolveCreateResponse = (
  storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>,
  entity: EntityType,
  payload: any
) => {
  switch (entity) {
    case "deal":
      if ((payload as EntityData<typeof entity>).data.info.status === "lead")
        storeAPI.dispatch({
          type: entityConfigs[entity].list.sliceActions.addToList.type,
          payload: { payload: payload, listId: "leads" }
        });
      storeAPI.dispatch({
        type: entityConfigs[entity].list.sliceActions.addToList.type,
        payload: { payload: payload, listId: "table" }
      });
      storeAPI.dispatch({
        type: entityConfigs[entity].list.sliceActions.addToList.type,
        payload: {
          payload: payload,
          addToEnd: true,
          listId: "table_" + new Date(payload.createdAt).toISOString().slice(0, 10)
        }
      });
      break;
    case "lender_decision":
      //@ts-ignore
      if (Array.isArray(payload.entities) && payload.entities.length > 0) {
        storeAPI.dispatch({
          type: entityConfigs[entity].list.sliceActions.addToList.type,
          //@ts-ignore
          payload: { payload: payload.entities, listId: "table" }
        });
      } else if (payload?._id) {
        storeAPI.dispatch({
          type: entityConfigs[entity].list.sliceActions.addToList.type,
          payload: { payload: payload, listId: "table" }
        });
      }
      break;
    default:
      if (entityConfigs[entity]?.list?.sliceActions) {
        storeAPI.dispatch({
          type: entityConfigs[entity].list.sliceActions.addToList.type,
          payload: { payload: payload, listId: "table" }
        });
        const listIds = getListIdByEntity(entity, payload);
        listIds.forEach((listId: string) => {
          storeAPI.dispatch({
            type: entityConfigs[entity].list.sliceActions.addToList.type,
            payload: { payload: payload, listId: listId }
          });
        });
      }
  }
};
const resolveUpdateResponse = (
  storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>,
  entity: EntityType,
  payload: any
) => {
  if (entity === "note") return;
  if (entity === "customer_care_note") return;
  if (entity === "dealer_note") return;
  if (entity === "title_issue_note") return;
  if (entity === "dealership_ticket_note") return;
  if (entity === "lender_note") return;
  if (entity === "stipulation") return;
  if (entity === "floorplanxpress") {
    return storeAPI.dispatch({
      type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
      payload: { payload: payload, index: 0, listId: payload.data.dealId }
    });
  }
  // if (entity === "role") {
  //   makeHttpRequestWithResponse(socket, {
  //     requestTopic: "current_user_permissions",
  //     payload: {}
  //   })
  //     .then((payload: ResponsePayload) => {
  //       if (payload?.status === "success") {
  //         storeAPI.dispatch({
  //           type: setPermissions.type,
  //           payload: payload.message
  //         });

  //         //@ts-ignore
  //         if (payload?.message?.api_status?.read?.hasPermission)
  //           storeAPI.dispatch(getApiStatusAction("table"));
  //       }
  //     })
  //     .catch((error: string) => {
  //       // TODO: should we do something if we can't get permissions?
  //       devlog("Failed to get permissions: ", error);
  //     });
  // }

  const currentListState = storeAPI.getState()[`list${capitalizeAndRemoveDashes(entity)}Slice`];

  if (currentListState) {
    if (entity === "lock") {
      const foundIndex = currentListState["all"]?.entities?.findIndex(
        (el: any) => el._id === payload._id
      );
      const index = foundIndex >= 0 ? foundIndex : currentListState["all"]?.entities?.length || 0;
      storeAPI.dispatch({
        type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
        payload: { payload, index, listId: "all" }
      });
    } else {
      Object.keys(currentListState).forEach((listId) => {
        const index = currentListState[listId].entities?.findIndex(
          (el: any) => el._id === payload._id
        );

        const entry = currentListState[listId].entities?.[index];
        if (entry && new Date(payload.updatedAt).getTime() > new Date(entry.updatedAt).getTime()) {
          const potentiallyChangedData =
            payload.data !== undefined
              ? convertToInternalFormat(entity, payload.data)
              : payload.data;
          storeAPI.dispatch({
            type: entityConfigs[entity as EntityType].list.sliceActions.editList.type,
            payload: { payload: { ...payload, data: potentiallyChangedData }, index, listId }
          });
        }
      });
    }
  }

  const entityEntry = storeAPI.getState()?.[`entitySlice`]?.[entity]?.[payload._id];

  if (
    entityEntry &&
    (new Date(payload.updatedAt).getTime() > new Date(entityEntry.updatedAt).getTime() ||
      entity === "document_template")
  ) {
    if (payload.data !== undefined) {
      payload.data = convertToInternalFormat(entity as any, payload.data);
    }
    storeAPI.dispatch({
      type: entitySlice.actions.update.type,
      payload: { payload, entity, _id: payload._id }
    });
  }
};
const isHttpRequestPath = (request: unknown): request is HttpRequestPaths =>
  httpRequestPaths.find((x) => x === request) === undefined ? false : true;

type Method = "get" | "post" | "put" | "patch" | "delete";

export type HttpRequestData<T = HttpRequestPaths> = (T extends "dealertrack_deal"
  ? {
      method: "post";
      entity: T;
    }
  : {
      method: Method;
      entity: T;
    }) & {
  path: string;
  body?: unknown;
  query?: unknown;
};

export const transformOldTopicToAxiosRequest = (topic: RequestsTopics, payload: unknown) => {
  const httpPath = convertTopicToHttpPath(topic);
  switch (topic) {
    case "edit_document_validation":
    case "express_ex1_contract_void":
    case "express_ex1_contract":
    case "express_ex1_dealer_product":
    case "express_ex1_pre_rate":
    case "express_ex1_rate":
    case "new_lender_decisions":
    case "protective_delete_contract":
    case "protective_get_dealer_details":
    case "protective_get_rv_rates":
    case "protective_submit_rv_contracts":
    case "report_token":
    case "api_status":
    case "asc_cancel_transaction":
    case "asc_get_rate":
    case "asc_process_transaction":
    case "dealertrack_deal_add":
    case "send_generated_contract":
    case "change_user_pin":
      return getRequestData(httpPath, "post", undefined, payload);
    case "unapproved_users":
    case "all_applicants":
    case "all_settings":
    case "all_dealership_programs":
    case "all_manager_programs":
    case "all_dealership_bills_reports":
    case "all_contract_types":
    case "all_contracts":
    case "all_additional_required_documents":
    case "all_credit_scores":
    case "all_credit_smarts_application":
    case "all_chargebacks":
    case "all_custom_reports":
    case "all_chargeback_bills":
    case "all_dealerships":
    case "all_deals":
    case "all_dmvs":
    case "all_lender_tickets":
    case "all_document_templates":
    case "all_document_validations":
    case "all_external_credit_applications":
    case "all_files":
    case "all_funding_documents":
    case "all_printed_documents":
    case "all_lender_decisions":
    case "all_lenders":
    case "all_locks":
    case "all_stipulations":
    case "all_notifications":
    case "all_other_vendors":
    case "all_payoff_banks":
    case "all_roles":
    case "all_states":
    case "all_dealership_tickets":
    case "all_title_issues":
    case "all_prescreens":
    case "all_wfd_users":
    case "all_column_groups":
    case "all_table_settings":
    case "all_business_reports":
    case "all_checklists":
    case "all_notes":
    case "all_customer_care_notes":
    case "all_credit_score_ranges":
    case "all_lender_ticket_types":
    case "all_dealer_notes":
    case "all_title_issue_notes":
    case "all_dealership_ticket_notes":
    case "all_lender_notes":
    case "all_dealership_statistics":
    case "deals_count_by_status":
    case "deals_count_by_products":
    case "deals_commision_by_status":
    case "deals_commision_by_days":
    case "salesrep_deals":
      return getRequestData(httpPath, "get", undefined, undefined, payload);
    case "all_deals_by_ids":
    case "all_title_issues_by_ids":
      return getRequestData(httpPath, "post", undefined, payload, payload, true);
    case "all_floorplanxpress":
      return getRequestData(httpPath, "get");
    case "all_histories":
    case "all_vehicle_insurance_companies":
      return getRequestData(httpPath, "get", undefined, undefined, payload);
    case "appone_import_application":
      return getRequestData(httpPath, "post", undefined, payload);
    case "deals_order":
    case "deals_order_many":
      return getRequestData(httpPath, "post", undefined, payload);
    case "delete_all_notifications":
    case "delete_firebase_user":
      return getRequestData(httpPath, "delete");
    case "delete_applicant":
    case "delete_dealership_program":
    case "delete_credit_score_range":
    case "delete_lender_ticket_type":
    case "delete_manager_program":
    case "delete_note":
    case "delete_customer_care_note":
    case "delete_dealer_note":
    case "delete_title_issue_note":
    case "delete_dealership_ticket_note":
    case "delete_lender_note":
    case "delete_contract_type":
    case "delete_contract":
    case "delete_chargeback":
    case "delete_credit_smarts_application":
    case "delete_additional_required_document":
    case "delete_custom_report":
    case "delete_deal":
    case "delete_dealership":
    case "delete_dmv":
    case "delete_prescreen":
    case "delete_column_group":
    case "delete_lender_ticket":
    case "delete_stipulation":
    case "delete_document_template":
    case "delete_document_validation":
    case "delete_external_credit_application":
    case "delete_file":
    case "delete_funding_document":
    case "delete_printed_document":
    case "delete_lender_decision":
    case "delete_lender":
    case "delete_notification":
    case "delete_other_vendor":
    case "delete_payoff_bank":
    case "delete_role":
    case "delete_state":
    case "delete_title_issue":
    case "delete_dealership_ticket":
    case "delete_wfd_user":
    case "delete_vehicle_insurance_company":
    case "delete_business_report":
      return getRequestData(httpPath, "delete", (payload as any)._id);
    case "email_send":
      return getRequestData(httpPath, "post", undefined, payload);
    case "get_income_report":
      return getRequestData(httpPath, "get", (payload as any)._id);
    case "new_applicant":
    case "new_credit_score_range":
    case "new_lender_ticket_type":
    case "new_contract_type":
    case "new_contract":
    case "new_chargeback":
    case "new_additional_required_document":
    case "new_dealership_program":
    case "new_manager_program":
    case "new_dealership_bills_report":
    case "new_credit_score":
    case "new_custom_report":
    case "new_external_credit_application_deal":
    case "new_deal":
    case "new_dealership":
    case "new_lender_ticket":
    case "new_delivered_deal":
    case "new_dmv":
    case "new_document_template":
    case "new_document_validation":
    case "new_external_credit_application":
    case "new_file":
    case "new_funding_document":
    case "new_printed_document":
    case "add_chargeback_bills":
    case "new_lender_decision":
    case "new_lender":
    case "new_other_vendor":
    case "new_generate_contract_for_print":
    case "unparsed_required_document":
    case "new_note":
    case "new_customer_care_note":
    case "new_column_group":
    case "new_dealer_note":
    case "new_title_issue_note":
    case "new_dealership_ticket_note":
    case "new_lender_note":
    case "new_generate_contract":
    case "new_payoff_bank":
    case "new_deal_from_parsed_document":
    case "new_role":
    case "new_checklist":
    case "new_state":
    case "new_prescreen":
    case "new_stipulation":
    case "new_title_issue":
    case "new_rollback_status":
    case "new_wfd_user":
    case "new_vehicle_insurance_company":
    case "new_business_report":
    case "new_table_settings":
    case "resend_esign_email":
    case "export_column_group":
      return getRequestData(httpPath, "post", undefined, payload);
    case "get_applicant":
    case "get_contract_type":
    case "get_contract":
    case "get_dealership_program":
    case "get_manager_program":
    case "get_credit_score":
    case "get_chargeback":
    case "get_additional_required_document":
    case "get_custom_report":
    case "get_deal":
    case "send_approved_documents":
    case "get_dealership":
    case "get_delivered_deal":
    case "get_dmv":
    case "get_credit_score_range":
    case "get_lender_ticket_type":
    case "get_lender_ticket":
    case "get_document_template":
    case "get_document_validation":
    case "get_external_credit_application":
    case "get_file":
    case "get_prescreen":
    case "get_funding_document":
    case "get_lender_decision":
    case "get_lender":
    case "get_other_vendor":
    case "get_payoff_bank":
    case "get_role":
    case "get_state":
    case "get_title_issue":
    case "get_dealership_ticket":
    case "get_wfd_user":
    case "get_vehicle_insurance_company":
    case "get_lender_decisions":
    case "get_history":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "recover_applicant":
    case "recover_contract_type":
    case "recover_dealership_program":
    case "recover_manager_program":
    case "recover_contract":
    case "recover_credit_smarts_application":
    case "recover_custom_report":
    case "recover_deal":
    case "recover_additional_required_document":
    case "recover_dealership":
    case "recover_dmv":
    case "recover_prescreen":
    case "recover_credit_score_range":
    case "recover_lender_ticket_type":
    case "recover_chargeback":
    case "recover_lender_ticket":
    case "recover_document_template":
    case "recover_external_credit_application":
    case "recover_file":
    case "recover_funding_document":
    case "recover_lender_decision":
    case "recover_lender":
    case "recover_other_vendor":
    case "recover_payoff_bank":
    case "recover_role":
    case "recover_state":
    case "recover_title_issue":
    case "recover_dealership_ticket":
    case "recover_wfd_user":
    case "recover_vehicle_insurance_company":
    case "recover_business_report":
      return getRequestData(httpPath, "patch", (payload as any)?._id);
    case "update_lock":
    case "update_sequence":
      return getRequestData(httpPath, "put", undefined, payload);
    case "update_applicant":
    case "update_credit_score_range":
    case "update_lender_ticket_type":
    case "update_contract_type":
    case "update_contract":
    case "update_credit_score":
    case "update_credit_smarts_application":
    case "update_custom_report":
    case "update_deal":
    case "update_dealership":
    case "update_dealership_program":
    case "update_manager_program":
    case "update_dmv":
    case "update_prescreen":
    case "update_lender_ticket":
    case "update_document_template":
    case "update_external_credit_application":
    case "update_file":
    case "update_chargeback":
    case "update_additional_required_document":
    case "update_funding_document":
    case "update_lender_decision":
    case "update_lender":
    case "update_notification":
    case "update_other_vendor":
    case "update_payoff_bank":
    case "update_role":
    case "update_state":
    case "update_note":
    case "update_customer_care_note":
    case "update_title_issue_note":
    case "update_dealership_ticket_note":
    case "update_dealer_note":
    case "update_lender_note":
    case "update_title_issue":
    case "update_dealership_ticket":
    case "update_column_group":
    case "update_wfd_user":
    case "update_vehicle_insurance_company":
    case "update_business_report":
    case "update_one_span_signing":
    case "reject_printed_document":
    case "approve_printed_document":
    case "recognize_funding_document":
    case "update_stipulation":
    case "update_printed_document":
      return getRequestData(httpPath, "put", (payload as any)?._id, payload);
    case "update_settings":
    case "update_table_settings":
    case "update_checklist":
      return getRequestData(httpPath, "put", (payload as any)?._id, payload);
    case "vin_check":
    case "sales_order_to_invoice_and_vendor_bill":
      return getRequestData(httpPath, "post", undefined, payload);
    case "dealership_link_generator":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "dealership_email_verification_link":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "dealership_send_generated_link":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "lender_email_verification_link":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "lender_link_generator":
      return getRequestData(httpPath, "get", (payload as any)?._id);
    case "lender_send_generated_link":
      return getRequestData(httpPath, "get", (payload as any)?._id);

    default:
      return assertNever(topic);
  }
};
const convertTopicToHttpPath = (entity: RequestsTopics): HttpRequestPaths => {
  switch (entity) {
    case "new_chargeback":
      return "chargeback";
    case "update_chargeback":
      return "chargeback";
    case "get_chargeback":
      return "chargeback";
    case "recover_chargeback":
      return "chargeback";
    case "delete_chargeback":
      return "chargeback";
    case "all_chargebacks":
      return "chargeback";
    case "all_chargeback_bills":
      return "chargeback_bills";
    case "add_chargeback_bills":
      return "chargeback_bills";
    case "resend_esign_email":
      return "resend_esign_email";
    case "all_deals_by_ids":
      return "deals_by_ids";
    case "all_title_issues_by_ids":
      return "title_issues_by_ids";
    case "all_checklists":
      return "checklist";
    case "send_approved_documents":
      return "send_approved_documents";
    case "lender_email_verification_link":
      return "lender_email_verification_link";
    case "lender_send_generated_link":
      return "lender_send_generated_link";
    case "lender_link_generator":
      return "lender_link_generator";
    case "dealership_link_generator":
      return "dealership_link_generator";
    case "dealership_email_verification_link":
      return "dealership_email_verification_link";
    case "dealership_send_generated_link":
      return "dealership_send_generated_link";
    case "export_column_group":
      return "export_column_group";
    case "new_external_credit_application_deal":
      return "external_credit_application_deal";
    case "new_printed_document":
      return "printed_document";
    case "all_printed_documents":
      return "printed_document";
    case "delete_printed_document":
      return "printed_document";
    case "new_generate_contract_for_print":
      return "generate_contract_for_print";
    case "new_generate_contract":
      return "generate_contract";
    case "new_dealership_bills_report":
      return "dealership_bills_report";
    case "new_deal_from_parsed_document":
      return "deal_from_parsed_document";
    case "new_rollback_status":
      return "rollback_status";
    case "new_dealership_program":
      return "dealership_program";
    case "new_manager_program":
      return "manager_program";
    case "new_table_settings":
      return "table_settings";
    case "update_table_settings":
      return "table_settings";
    case "update_dealership_program":
      return "dealership_program";
    case "update_manager_program":
      return "manager_program";
    case "all_table_settings":
      return "table_settings";
    case "all_dealership_bills_reports":
      return "dealership_bills_report";
    case "all_dealership_programs":
      return "dealership_program";
    case "all_manager_programs":
      return "manager_program";
    case "all_dealership_statistics":
      return "dealership_statistics";
    case "all_additional_required_documents":
      return "additional_required_document";
    case "get_dealership_program":
      return "dealership_program";
    case "get_manager_program":
      return "manager_program";
    case "get_history":
      return "history";
    case "get_applicant":
      return "applicant";
    case "all_applicants":
      return "applicant";
    case "recover_applicant":
      return "applicant";
    case "get_contract_type":
      return "contract_type";
    case "get_additional_required_document":
      return "additional_required_document";
    case "get_contract":
      return "contract";
    case "get_credit_score":
      return "credit_score";
    case "get_credit_score_range":
      return "credit_score_range";
    case "get_lender_ticket_type":
      return "lender_ticket_type";
    case "get_custom_report":
      return "custom_report";
    case "get_deal":
      return "deal";
    case "get_dealership":
      return "dealership";
    case "get_delivered_deal":
      return "delivered_deal";
    case "get_dmv":
      return "dmv";
    case "get_prescreen":
      return "prescreen";
    case "get_lender_ticket":
      return "lender_ticket";
    case "get_document_template":
      return "document_template";
    case "get_document_validation":
      return "document_validation";
    case "get_external_credit_application":
      return "external_credit_application";
    case "get_file":
      return "file";
    case "get_funding_document":
      return "funding_document";
    case "reject_printed_document":
      return "reject_printed_document";
    case "approve_printed_document":
      return "approve_printed_document";
    case "recognize_funding_document":
      return "recognize_funding_document";
    case "get_lender_decision":
      return "lender_decision";
    case "get_lender_decisions":
      return "lender_decision";
    case "get_lender":
      return "lender";
    case "get_other_vendor":
      return "other_vendor";
    case "get_payoff_bank":
      return "payoff_bank";
    case "get_role":
      return "role";
    case "get_state":
      return "state";
    case "get_dealership_ticket":
      return "dealership_ticket";
    case "get_title_issue":
      return "title_issue";
    case "get_wfd_user":
      return "wfd_user";
    case "get_vehicle_insurance_company":
      return "vehicle_insurance_company";
    case "new_business_report":
      return "business_report";
    case "update_business_report":
      return "business_report";
    case "delete_business_report":
      return "business_report";
    case "delete_dealership_program":
      return "dealership_program";
    case "delete_manager_program":
      return "manager_program";
    case "all_business_reports":
      return "business_report";
    case "recover_business_report":
      return "business_report";
    case "recover_dealership_program":
      return "dealership_program";
    case "recover_manager_program":
      return "manager_program";
    case "all_settings":
      return "settings";
    case "all_dealership_ticket_notes":
      return "dealership_ticket_note";
    case "all_title_issue_notes":
      return "title_issue_note";
    case "all_contract_types":
      return "contract_type";
    case "all_contracts":
      return "contract";
    case "all_credit_scores":
      return "credit_score";
    case "all_credit_smarts_application":
      return "credit_smarts_application";
    case "all_custom_reports":
      return "custom_report";
    case "all_dealerships":
      return "dealership";
    case "all_deals":
      return "deal";
    case "all_stipulations":
      return "stipulation";
    case "all_dmvs":
      return "dmv";
    case "all_prescreens":
      return "prescreen";
    case "all_lender_tickets":
      return "lender_ticket";
    case "all_document_templates":
      return "document_template";
    case "all_document_validations":
      return "document_validation";
    case "all_external_credit_applications":
      return "external_credit_application";
    case "all_files":
      return "file";
    case "all_customer_care_notes":
      return "customer_care_note";
    case "all_notes":
      return "note";
    case "all_dealer_notes":
      return "dealer_note";
    case "all_lender_notes":
      return "lender_note";
    case "all_floorplanxpress":
      return "floorplanxpress";
    case "all_funding_documents":
      return "funding_document";
    case "all_histories":
      return "history";
    case "all_credit_score_ranges":
      return "credit_score_range";
    case "all_lender_ticket_types":
      return "lender_ticket_type";
    case "all_lender_decisions":
      return "lender_decision";
    case "all_lenders":
      return "lender";
    case "all_column_groups":
      return "column_group";
    case "all_locks":
      return "lock";
    case "all_notifications":
      return "notification";
    case "all_other_vendors":
      return "other_vendor";
    case "all_payoff_banks":
      return "payoff_bank";
    case "all_roles":
      return "role";
    case "all_states":
      return "state";
    case "all_dealership_tickets":
      return "dealership_ticket";
    case "all_title_issues":
      return "title_issue";
    case "all_wfd_users":
      return "wfd_user";
    case "all_vehicle_insurance_companies":
      return "vehicle_insurance_company";
    case "api_status":
      return "api_status";
    case "appone_import_application":
      return "appone_import_application";
    case "asc_cancel_transaction":
      return "asc_cancel_transaction";
    case "asc_get_rate":
      return "asc_get_rate";
    case "asc_process_transaction":
      return "asc_process_transaction";
    case "dealertrack_deal_add":
      return "dealertrack_deal";
    case "deals_order":
      return "deals_order";
    case "delete_all_notifications":
      return "notification";
    case "delete_applicant":
      return "applicant";
    case "delete_contract_type":
      return "contract_type";
    case "delete_additional_required_document":
      return "additional_required_document";
    case "delete_contract":
      return "contract";
    case "delete_column_group":
      return "column_group";
    case "delete_dealership_ticket_note":
      return "dealership_ticket_note";
    case "delete_title_issue_note":
      return "title_issue_note";
    case "delete_credit_smarts_application":
      return "credit_smarts_application";
    case "delete_custom_report":
      return "custom_report";
    case "delete_deal":
      return "deal";
    case "delete_dealership":
      return "dealership";
    case "delete_dmv":
      return "dmv";
    case "delete_prescreen":
      return "prescreen";
    case "delete_lender_ticket":
      return "lender_ticket";
    case "delete_customer_care_note":
      return "customer_care_note";
    case "delete_note":
      return "note";
    case "delete_dealer_note":
      return "dealer_note";
    case "delete_lender_note":
      return "lender_note";
    case "delete_document_template":
      return "document_template";
    case "delete_document_validation":
      return "document_validation";
    case "delete_external_credit_application":
      return "external_credit_application";
    case "delete_stipulation":
      return "stipulation";
    case "delete_credit_score_range":
      return "credit_score_range";
    case "delete_lender_ticket_type":
      return "lender_ticket_type";
    case "delete_file":
      return "file";
    case "delete_firebase_user":
      return "firebase_user";
    case "delete_funding_document":
      return "funding_document";
    case "delete_lender_decision":
      return "lender_decision";
    case "delete_lender":
      return "lender";
    case "delete_notification":
      return "notification";
    case "delete_other_vendor":
      return "other_vendor";
    case "delete_payoff_bank":
      return "payoff_bank";
    case "delete_role":
      return "role";
    case "delete_state":
      return "state";
    case "delete_dealership_ticket":
      return "dealership_ticket";
    case "delete_title_issue":
      return "title_issue";
    case "delete_wfd_user":
      return "wfd_user";
    case "delete_vehicle_insurance_company":
      return "vehicle_insurance_company";
    case "edit_document_validation":
      return "document_validation";
    case "email_send":
      return "email_send";
    case "express_ex1_contract_void":
      return "express_ex1_contract_void";
    case "express_ex1_contract":
      return "express_ex1_contract";
    case "express_ex1_dealer_product":
      return "express_ex1_dealer_product";
    case "express_ex1_pre_rate":
      return "express_ex1_pre_rate";
    case "express_ex1_rate":
      return "express_ex1_rate";
    case "get_income_report":
      return "income_report";
    case "new_applicant":
      return "applicant";
    case "new_contract_type":
      return "contract_type";
    case "new_contract":
      return "contract";
    case "new_credit_score":
      return "credit_score";
    case "new_credit_score":
      return "credit_score";
    case "new_custom_report":
      return "custom_report";
    case "new_deal":
      return "deal";
    case "new_dealership_ticket_note":
      return "dealership_ticket_note";
    case "new_title_issue_note":
      return "title_issue_note";
    case "new_dealership":
      return "dealership";
    case "new_additional_required_document":
      return "additional_required_document";
    case "new_delivered_deal":
      return "delivered_deal";
    case "new_dmv":
      return "dmv";
    case "new_prescreen":
      return "prescreen";
    case "new_lender_ticket":
      return "lender_ticket";
    case "new_document_template":
      return "document_template";
    case "new_checklist":
      return "checklist";
    case "new_document_validation":
      return "document_validation";
    case "new_external_credit_application":
      return "external_credit_application";
    case "new_credit_score_range":
      return "credit_score_range";
    case "new_lender_ticket_type":
      return "lender_ticket_type";
    case "new_customer_care_note":
      return "customer_care_note";
    case "new_note":
      return "note";
    case "new_dealer_note":
      return "dealer_note";
    case "new_lender_note":
      return "lender_note";
    case "new_stipulation":
      return "stipulation";
    case "new_file":
      return "file";
    case "new_funding_document":
      return "funding_document";
    case "new_lender_decision":
      return "lender_decision";
    case "new_lender_decisions":
      return "lender_decisions";
    case "new_column_group":
      return "column_group";
    case "new_lender":
      return "lender";
    case "new_other_vendor":
      return "other_vendor";
    case "new_payoff_bank":
      return "payoff_bank";
    case "new_role":
      return "role";
    case "new_state":
      return "state";
    case "new_title_issue":
      return "title_issue";
    case "new_wfd_user":
      return "wfd_user";
    case "new_vehicle_insurance_company":
      return "vehicle_insurance_company";
    case "protective_delete_contract":
      return "protective_delete_contract";
    case "protective_get_dealer_details":
      return "protective_get_dealer_details";
    case "protective_get_rv_rates":
      return "protective_get_rv_rates";
    case "protective_submit_rv_contracts":
      return "protective_submit_rv_contracts";
    case "recover_contract_type":
      return "contract_type";
    case "recover_contract":
      return "contract";
    case "recover_credit_smarts_application":
      return "credit_smarts_application";
    case "recover_custom_report":
      return "custom_report";
    case "recover_deal":
      return "deal";
    case "recover_dealership":
      return "dealership";
    case "recover_dmv":
      return "dmv";
    case "recover_prescreen":
      return "prescreen";
    case "recover_lender_ticket":
      return "lender_ticket";
    case "recover_document_template":
      return "document_template";
    case "recover_external_credit_application":
      return "external_credit_application";
    case "recover_file":
      return "file";
    case "recover_additional_required_document":
      return "additional_required_document";
    case "recover_funding_document":
      return "funding_document";
    case "recover_lender_decision":
      return "lender_decision";
    case "recover_lender":
      return "lender";
    case "recover_other_vendor":
      return "other_vendor";
    case "recover_payoff_bank":
      return "payoff_bank";
    case "recover_role":
      return "role";
    case "recover_state":
      return "state";
    case "recover_credit_score_range":
      return "credit_score_range";
    case "recover_lender_ticket_type":
      return "lender_ticket_type";
    case "recover_dealership_ticket":
      return "dealership_ticket";
    case "recover_title_issue":
      return "title_issue";
    case "recover_wfd_user":
      return "wfd_user";
    case "recover_vehicle_insurance_company":
      return "vehicle_insurance_company";
    case "report_token":
      return "report_token";
    case "unapproved_users":
      return "unapproved_user";
    case "update_applicant":
      return "applicant";
    case "update_contract_type":
      return "contract_type";
    case "update_contract":
      return "contract";
    case "update_credit_score":
      return "credit_score";
    case "update_credit_smarts_application":
      return "credit_smarts_application";
    case "update_custom_report":
      return "custom_report";
    case "update_column_group":
      return "column_group";
    case "update_deal":
      return "deal";
    case "update_additional_required_document":
      return "additional_required_document";
    case "update_dealership":
      return "dealership";
    case "update_dmv":
      return "dmv";
    case "update_prescreen":
      return "prescreen";
    case "update_lender_ticket":
      return "lender_ticket";
    case "update_customer_care_note":
      return "customer_care_note";
    case "update_note":
      return "note";
    case "update_dealer_note":
      return "dealer_note";
    case "update_dealership_ticket_note":
      return "dealership_ticket_note";
    case "update_title_issue_note":
      return "title_issue_note";
    case "update_lender_note":
      return "lender_note";
    case "update_document_template":
      return "document_template";
    case "update_external_credit_application":
      return "external_credit_application";
    case "update_checklist":
      return "checklist";
    case "update_file":
      return "file";
    case "update_stipulation":
      return "stipulation";
    case "deals_order_many":
      return "deals_order_many";
    case "update_funding_document":
      return "funding_document";
    case "update_lender_decision":
      return "lender_decision";
    case "update_lender":
      return "lender";
    case "update_lock":
      return "lock";
    case "update_notification":
      return "notification";
    case "update_other_vendor":
      return "other_vendor";
    case "update_payoff_bank":
      return "payoff_bank";
    case "update_role":
      return "role";
    case "update_credit_score_range":
      return "credit_score_range";
    case "update_lender_ticket_type":
      return "lender_ticket_type";
    case "update_sequence":
      return "sequence";
    case "update_state":
      return "state";
    case "update_settings":
      return "settings";
    case "update_dealership_ticket":
      return "dealership_ticket";
    case "update_title_issue":
      return "title_issue";
    case "update_wfd_user":
      return "wfd_user";
    case "update_printed_document":
      return "printed_document";
    case "update_vehicle_insurance_company":
      return "vehicle_insurance_company";
    case "new_generate_contract_for_print":
      return "generate_contract_for_print";
    case "send_generated_contract":
      return "send_generated_contract";
    case "vin_check":
      return "vin_check";
    case "update_one_span_signing":
      return "one_span_signing";
    case "sales_order_to_invoice_and_vendor_bill":
      return "sales_order_to_invoice_and_vendor_bill";
    case "deals_count_by_status":
      return "deals_count_by_status";
    case "deals_count_by_products":
      return "deals_count_by_products";
    case "deals_commision_by_status":
      return "deals_commision_by_status";
    case "deals_commision_by_days":
      return "deals_commision_by_days";
    case "salesrep_deals":
      return "salesrep_deals";
    case "unparsed_required_document":
      return "unparsed_required_document";
    case "change_user_pin":
      return "change_user_pin";
  }
};

const getRequestData = (
  path: HttpRequestPaths,
  method: Method,
  id?: string,
  body?: any,
  query?: any,
  excludeQuery = false
) => {
  if (isHttpRequestPath(path)) {
    return {
      method,
      entity: path,
      path: `${path}/${id ?? ""}`,
      ...(body === undefined ? {} : { body }),
      query: {
        search: (query as any)?.search,
        with_deleted: (query as any)?.with_deleted,
        aggregateFirst: (query as any)?.aggregateFirst,
        ...(excludeQuery ? {} : { query: (query as any)?.query }),
        options: (query as any)?.options,
        queryParams: (query as any)?.queryParams
      }
    };
  } else {
    throw new Error(`Unknown request: ${path}`);
  }
};
axios.interceptors.request.use((x) => {
  // to avoid overwriting if another interceptor
  // already defined the same object (meta)
  // @ts-ignore
  x.meta = x.meta || {};
  // @ts-ignore
  x.meta.requestStartedAt = new Date().getTime();
  return x;
});
axios.interceptors.response.use((x) => {
  // TODO: maybe create better tools for nerds :)
  // @ts-ignore
  const responseTime = new Date().getTime() - x?.config?.meta?.requestStartedAt;
  // console.info(
  //   `Execution time for: ${x.config.url} -> Server: ${x.headers["x-response-time"]} | Server and Network: ${responseTime} ms`
  // );
  const fpsContainer = document.getElementById("fps");

  if (fpsContainer !== null) {
    //@ts-ignore
    fpsContainer.innerHTML =
      // `URL: ${x.config.url}
      `S: ${parseInt(x.headers["x-response-time"])} ms | N: ${responseTime} ms <br/>` +
      fpsContainer.innerHTML.slice(0, 300);
  }
  return x;
});
export const makeHttpRequestWithResponse = (
  { method, path, body, query, entity }: HttpRequestData,
  operation?: string
) => {
  return new Promise<RestResponse>((resolve, reject) => {
    auth.currentUser?.getIdToken().then((token) => {
      //console.log(" method, path, body, query ,entity", method, path, body, query, entity);
      if (method && path) {
        // console.log(
        //   "SENDING ",
        //   method,
        //   " REQUEST ",
        //   path,
        //   " BODY ",
        //   body,
        //   " QUERY ",
        //   query,
        //   " ENTITY ",
        //   entity
        // );
        axios({
          method,
          url: `${process.env.REACT_APP_HTTP_URL as string}/api/v1/${path}`,
          data: body ?? {},
          ...((query as any)?.queryParams ? { params: (query as any)?.queryParams } : {}),
          headers: {
            ...(operation ? { validationOperation: operation } : {}),
            Authorization: `Bearer ${token}`,
            ...(query ? { query: JSON.stringify(query) } : {})
          }
        })
          // @ts-ignore
          .then((res: RestResponse) => {
            return resolve(res);
          })
          .catch((err) => {
            return reject({
              message:
                err?.response?.data?.message ??
                err?.response?.data?.result ??
                err?.message ??
                "Unknown error!",
              status: "error"
            });
          });
      } else {
        notifyWebhooks({ message: "Trying to send unknown request.", status: "error" });
        return reject({ message: "Trying to send unknown request.", status: "error" });
      }
    });
  }).then((payload: RestResponse) => {
    return payload;
  });
};
