import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import {
  EditDealershipRequest,
  EditDealershipRequestSend,
  PartialDealershipToEdit,
  PartialDealershipToSend
} from "./types";

export interface EditDealershipResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditDealershipRequestSend | PartialDealershipToSend,
  EditDealershipResponse
>("EditDealership", {
  request: "update_dealership"
});

export const editDealership = (request: EditDealershipRequest & Identifiable) => {
  const { representative, customerCareAssignee, contracts, ...rest } = request.data;
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    data: {
      ...rest,
      representativeId: representative?._id ?? null,
      customerCareAssigneeId: customerCareAssignee?._id ?? null,
      info: {
        ...rest.info,
        dealershipPrograms: rest?.info?.dealershipPrograms?.map((x) => ({
          endDate: x.endDate,
          startDate: x.startDate,
          dealershipProgramId: x?.dealershipProgram?._id
        })),
        titleManagement: {
          outstandingDeals: rest.info.titleManagement?.outstandingDeals,
          isRestricted: rest.info.titleManagement?.isRestricted,
          outstandingTitlesFundingLimit: rest.info.titleManagement?.outstandingTitlesFundingLimit,
          outstandingTitlesApplicationsLimit:
            rest.info.titleManagement?.outstandingTitlesApplicationsLimit,
          restrictionDate: rest.info.titleManagement?.restrictionDate,
          suspensions: {
            funding:
              (rest.info.titleManagement?.outstandingDeals || 0) >
              (rest.info.titleManagement?.outstandingTitlesFundingLimit || 0),
            applicationProcessing:
              (rest.info.titleManagement?.outstandingDeals || 0) >
              (rest.info.titleManagement?.outstandingTitlesApplicationsLimit || 0)
          }
        }
      }
    }
  });
};

export const editPartialDealership = (request: PartialDealershipToEdit & Identifiable) => {
  return socketAction({
    requestId: request.requestId,
    _id: request._id,
    approved: request?.approved ?? null,
    approvedAt: request.approvedAt ?? undefined,
    approvedByUserId: request.approvedByUserId ?? undefined,
    type: "partial",
    data: request.data
  });
};

export const editDealershipActions = socketMessageSlice.actions;

export default socketMessageSlice.reducer;
